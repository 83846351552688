import validateEN from 'vee-validate/dist/locale/en.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    hello: 'hello',
    eur: '€',
    share: 'share | shares',
    shareExtension: '(Drawn)',
    dividend: 'interest rate | interest rate',
    myDividend: 'My interest rate | My interest rate',
    myInterestRate: 'My interest rate',
    prospectus: 'prospectus',
    investment: 'investment | investments',
    fund: 'investment vehicle | investment vehicles',
    invest: 'invest',
    investIn: 'invest in this investment vehicle',
    investMore: 'invest more',
    investNow: 'invest now',
    amount: 'amount',
    payment: 'payment | payments',
    name: 'name | names',
    firstName: 'First name',
    surname: 'Last name',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    password: 'password | passwords',
    save: 'save',
    tier: 'tier',
    confirm: 'confirm',
    activate: 'activate',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    iDeal: 'iDeal',
    and: 'and',
    dashboard: 'dashboard',
    myDashboard: 'My dashboard',
    back: 'back',
    sold: 'sold',
    minimalInvestment: 'minimum investment',
    participation: 'participation | participations',
    transactionSize: 'transaction size',
    available: 'available',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identification',
    summary: 'summary',
    process: 'process | processes',
    processing: 'processing',
    error: 'error',
    success: 'success',
    paid: 'paid',
    fail: 'fail',
    failed: 'failed',
    canceled: 'canceled',
    cancel: 'cancel',
    expired: 'expired',
    open: 'open',
    step: 'step | steps',
    fullName: 'full name',
    telephone: 'telephone',
    companyName: 'company name',
    phone: 'phone',
    dateOfBirth: 'date of birth',
    bankAccount: 'bank account',
    sendDataChangeRequest: 'Send data change request',
    dataChangeRequestPending: 'Data change request is pending',
    dataChangeRequestValue: 'Requested value',
    iban: 'IBAN',
    city: 'city',
    country: 'country',
    address: 'address',
    houseNumber: 'house number',
    number: 'number',
    postalCode: 'postal code',
    welcome: 'welcome Mr./Mrs.',
    availability: 'availability',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'country of birth',
    additionalInfo: 'additional info',
    or: 'or',
    from: 'from',
    to: 'to',
    for: 'for',
    support: 'support',
    subject: 'subject',
    message: 'message',
    login: 'login',
    register: 'register',
    reset: 'reset',
    resetPass: 'reset password',
    setPass: 'set password',
    secondFactor: '2-factor authentication',
    revertSecondFactor: 'revert 2-factor authentication',
    nationality: 'nationality',
    realEstate: 'real estate index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'warning',
    document: 'document | documents',
    location: 'location | locations',
    floorplan: 'floorplan',
    benefit: 'benefit | benefits',
    company: 'company | companies',
    setting: 'setting | settings',
    logout: 'logout',
    help: 'help',
    optional: 'optional',
    source: 'source',
    portfolio: 'portfolio',
    fundsLink: 'investment vehicle | investment vehicles',
    knowledge: 'knowledge base',
    glossary: 'glossary',
    cookie: 'cookie | cookies',
    no: 'no',
    yes: 'yes, this is ok',
    loadMore: 'show more investment vehicles',
    loadLess: 'show less investment vehicles',
    verified: 'account verified',
    pending: 'account under verification',
    rejected: 'account rejected',
    notVerified: 'account not verified',
    year: 'year | years',
    yearInitials: 'y',
    perYear: 'per year',
    perYearShort: 'p/yr',
    myAccount: 'my account',
    my: 'my',
    details: 'details',
    na: 'n.a.',
    here: 'here',
    send: 'send',
    bond: 'bond | bonds',
    date: 'date',
    month: 'month | months',
    yearly: 'yearly',
    monthly: 'monthly',
    new: 'new',
    search: 'search',
    next: 'next',
    prev: 'prev',
    fundCompleted: 'completed',
    comingSoon: 'coming soon',
    incorrectPassword: 'incorrect password',
    autoLogOut: 'You have been automatically logged out due to inactivity.',
    investor: 'investor | investors',
    cookiePolicy: 'Cookie policy',
    copyright: 'Bloqhouse 2019',
    accountDisabled: 'Your account has been disabled.',
    contactSupport: 'Please contact {email} or call {phone} for support.',
    supportQuestion: 'How can we help you? Ask your question here:',
    websiteMaintenance: 'Website under maintenance.',
    closeModal: 'Close',
    paidOnline: 'Paid online',
    paidOffline: 'Paid offline',
    dutch: 'Dutch',
    english: 'English',
    gender: 'gender',
    male: 'male',
    female: 'female',
    bannerLegal: 'Banner legal',
    decline: 'Decline',
    accept: 'Accept',
    disabled: 'This option cannot be changed',
    more: 'more',
    endedOn: 'Ended on',
  },
  validations: {
    ...validateEN.messages,
    alpha_num: 'This field may only contain alpha-numeric characters',
    alpha_spaces: 'This field may only contain alphabetical characters and spaces',
    confirmed: 'This field confirmation does not match',
    email: 'This field must be a valid email',
    integer: 'This field must be a integer number',
    max: 'This field may not be greater than {length} characters',
    min: 'This field must be at least {length} characters',
    ext: 'File type must be of type {types}',
    required: 'This field is required',
    multiplier: {
      payment: 'The amount to participate has to be a multiplier of the price per share ({number}€).',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      accessibleAndUnderstandable: 'Easy and accessible investments in real estate',
      applyNowInvestor: 'Register now and become a real estate investor!',
      exploreProperties: 'Explore real estate',
      registerNow: 'Register now',
      explorePropertiesBtn: 'Explore investment vehicles',
    },
    accessible: {
      accessibleAndTransparent: 'Accessible and transparent',
      weMakeItVeryEasy: 'It is Bloqhouse\'s mission to make investing in real estate transparent and accessible',
      hasManyYears: 'Shared ownership in real estate makes it possible to invest without having to put time and effort into management',
      youCanStartBuying: 'After choosing one of the objects on the platform you can invest within minutes',
      onceTheSaleIsCompleted: `Your identity will be verified via iDIN and notes are for sale staring from € 100 to € 100,000 via iDEAL
        (notes above € 100,000 are via SEPA bank transfer)`,
      startBuying: 'Buy real estate notes starting from € 100',
      receivedMonth: 'Receive interest rate per quarter in Euros',
      tradeYourShares: 'Sell your notes via the trading platform (launch: end of 2019)',
    },
    fund: {
      emissionPrice: 'Price per Note',
      totalFundSize: 'Total investment vehicle size',
      seeDetails: 'See details',
    },
    counters: {
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      dividendsPerYear: 'interest rate per year',
      occupancyRate: 'Occupancy rate',
    },
    benefits: {
      exploreProperties: 'Explore properties',
      tab1: 'Invest directly',
      tab1Title: 'Invest directly',
      tab1Text: `Choose an object and decide how much you want to invest. With an average of one extra investment vehicle per two months,
      Bloqhouse strives to facilitate the largest real estate offer in the Benelux. You can put together your own portfolio
      that meets your criteria. In addition, it is easy to diversify with different types of real estate. There is a
      one-time cost of 2% connected to an investment`,
      tab2: 'Receive interest rate ',
      tab2Title: 'Receive interest rate ',
      tab2Text: `You receive interest rate on your investment every quarter.
        The interest rate consists of the rental income minus the management costs of the property`,
      tab3: 'Check your return',
      tab3Title: 'Check your return',
      tab3Text: `Check the performance of your investments on your dashboard. All of our properties are appraised every
        6 months by an RICS certified appraiser. This allows you to estimate the value fluctuation of the purchased real estate.
        The interest rate income is automatically included in the dashboard`,
      tab4: 'Liquidity',
      tab4Title: 'Liquidity',
      tab4Text1: `It’s possible to offer notes at any time via our trading platform. Selling is simple. Place your notes
        up for sale on the trading platform. Or alternatively: wait for the 5-yearly sale of the real estate,
        whereby investors will receive the potential surplus value of the property. The average time to sell a note is 4 days`,
      tab4Text2: `Depending on the chosen price, it may take longer or shorter. Please note: during the introduction period of
        the trading platform, the trading volume will be lower than average, which may result in a longer sale time`,
      tab5: 'Overhead',
      tab5Title: 'Overhead',
      tab5Text: `Investing in real estate is time-consuming and complex. Bloqhouse changes that. Paperwork, maintenance,
        lawyers and defaulters are now a thing of the past. Bloqhouse and the affiliated real estate investment vehicles arrange that for you,
        with many decades of market experience. This way you can profit from rental income and any potential value increases in real
        estate, without any hassle!`,
      tab5TextPoint1: 'Search for investments',
      tab5TextPoint2: 'Negotiation process',
      tab5TextPoint3: 'Paperwork and lawyers',
      tab5TextPoint4: 'Search for tenants',
      tab5TextPoint5: 'Maintenance and repairs',
      tab5TextPoint6: 'Valuations',
      tab5TextPoint7: 'Sale process',
      tab6: 'Our policy',
      tab6Title: 'Our policy',
      tab6Satisfied: 'Satisfied tenants:',
      tab6Text1: `Bloqhouse believes that satisfied tenants are the key to future success. Our real estate investment vehicles demand fair
        rents and maintain the property thoroughly, so that tenants stay for longer periods of time. This means stable rental
        income for you`,
      tab6SmartPurchases: 'Smart purchases:',
      tab6Text2: `Bloqhouse works together with large real estate investment vehicles in the Netherlands. As a result, we find
        the best purchases, which are usually and often owned by large companies or wealthy individuals`,
    },
    investing: {
      investingMadeSimple: 'Investing made simple',
      selectTheFund: 'Select the investment vehicle in which you want to invest',
      registerAndIdentify: 'Register and identify via iDIN',
      buyYourShares: 'Buy your Notes via iDeal',
      receiveMonth: 'Receive interest rate per quarter',
    },
    stock: {
      amsterdamStockExchange: 'Amsterdam Exchange Index',
      pastPerformance: 'Past performance is no guarantee for future results',
    },
    safeGuards: {
      investmentSafeguards: 'Investment safeguards',
      weTakeOurResponsabilities: 'We have taken responsibility to provide you with trust and ease-of-mind while investing',
      yourInvestmentsAreRingFenced: 'Your investments are protected',
      andAreSeparated: 'They are protected and separated from the assets and liabilities of Bloqhouse',
      compensationScheme: 'Compensation Scheme',
      protectsTheFunds: 'Your bank balance on the platform is insured via a Third-party investment vehicles foundation',
      financialConductAuthority: 'Dutch Financial Markets Authority',
      isTheBody: 'The platform is regulated by the Dutch Financial Markets Authority',
    },
    expectations: {
      assetsUnderManagement: 'Assets under management',
      capitalReturnedToInvestors: 'Capital returned to investors',
      rentalIncome: 'Rental income returned to investors',
    },
    faq: {
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the investment vehicle, the value of the property is divided pro rata among the shareholders at that time`,
      whenIsItSold: 'When is the real estate project sold / investment vehicle liquidated?',
      theAverage: 'The average duration of a investment vehicle is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the investment vehicles of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my notes?',
      sharesCanBe: 'Notes can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the investment vehicle is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the investment vehicle and real estate management?',
      thatIsBeingCared: 'The investment vehicle managers do this',
      questionsAnswers: 'Questions & Answers',
    },
    risks: {
      investmentRisks: 'Please note',
      generalRisksAssociated: `There are general risks associated with investing in property investment vehicles: the market risk, risk with regard to licensing
      requirements, risk of stock interest rate, return risk, financing and interest rate risk, rental and vacancy risk, tax risk, inflation risk and
        risks associated with the manager`,
      theMainRisks: 'Bloqhouse is not obliged to purchase notes',
      aDetailedDescription: 'A detailed description of the risks can be found in the prospectus of the related investment vehicle',
    },
    cookies: {
      allowUs: 'Do you allow us to store {cookiesLink}?',
      yourInfoAnalytics: 'Your information will be used for analytics and website optimisation',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Forgot password?',
      dontHaveAnAccountYet: 'Don\'t have an account yet?',
      accessYourBloqify: 'Access your Bloqify',
      enterYourPassword: 'Enter your password',
      pleaseActivate: 'Please activate your account using the activation link sent to your e-mail',
      userHasRole: 'You tried to log in with an admin account. Please use it in Bloqadmin only.',
      captchaTimeExpired: 'Captcha verification expired',
      tryAgain: 'Please try again',
    },
    register: {
      becomeAnInvestor: 'Become an investor',
      atLeast10Chars: 'At least 10 characters',
      alreadyHaveAnAccount: 'Already have an account?',
      thankYou: 'Thank you for registering',
      pleaseCheckEmail: 'Please check your e-mail to activate your account',
      itCanTake: 'It can take a few minutes to arrive',
      youCan: 'You can',
      afterActivating: 'after activating your account',
      confirmPassword: 'Confirm password',
    },
    reset: {
      getAPassword: 'Get a password reset link',
      sendResetLink: 'Send reset link',
      rememberYourPassword: 'Remember your password?',
      emailSent: 'If the provided email is registered, you should have received an email',
      pleaseCheckInbox: 'Please check your inbox',
      expiredError: 'For security reasons, this link was valid only for 24 hours. Please, insert your e-mail so we can send you an email with a new link to claim your password.',
    },
    verification: {
      emailVerification: 'E-mail verification',
      resetPassword: 'Reset password',
      claimPasswoord: 'Claim password',
      changeEmail: 'Change e-mail',
      verifyingSuccess: 'Everything is set, you can login now',
      verifyingError: 'Something went wrong! Register again',
      changeError: 'Something went wrong! Try again',
      changeSuccess: 'Everything is set, you can login',
      passwordLenght: 'Password has to be 10 or longer',
      changeDataRequestConfirmation: 'Are you sure you want to change your <strong>{type}</strong> from <em>{oldValue}</em> to <em>{newValue}<em/>',
      revertSecondFactor: 'You activated second factor authentication, on this page you can revert this action',
      revertSecondFactorSuccess: 'You successfully deactivated 2-factor authentication',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verify',
      dismiss: 'Dismiss',
      notNow: 'Not now',
      pending: 'Your account is being verified',
      pendingDescription: 'You will be notified soon by {fundManager}',
      youCanInvest: 'You can already invest in your first investment vehicle',
      youNeedApproval: 'You can only invest after verifying your account',
      upgradeTo: 'You still need to verify your account',
      basicUser: 'You have now only created an account',
    },
    balance: {
      totalBalance: 'My holdings',
      onTheseFunds: 'In these investment vehicles',
      annualStatement: 'Tax statement',
    },
    dividends: {
      onTheseFunds: 'From these investment vehicles',
      comingSoon: 'The percentage of interest rate received will be shown here soon',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Not in portfolio',
      totalValue: 'Total amount',
      totalValueAlternative: 'Drawn bond contribution',
      dealSize: 'Total loan size',
      numberOfDeals: 'Number of participations',
      myInvestment: 'Contributed capital',
      netDividendYield: 'Interest rate',
      netDividendYieldMine: 'My interest rate',
      startToInvest: 'Start to invest on your first investment vehicle',
      investInOtherFunds: 'Invest on other investment vehicles to earn more interest rate',
      investMoreOnSingleFund: 'Explore other properties',
      showAlltheFunds: 'Show all investment vehicles',
      myPortfolio: 'My Portfolio',
      myFunds: 'My investment vehicles',
      otherFunds: 'Other investment vehicles',
      myFundsPlaceholder: 'All the investment vehicles you have invested in will be listed here',
      myFundsStartInvest: 'Invest on your first investment vehicle',
      progress: 'Investment vehicle progress',
      more: 'more',
      premium: 'This is a Premium investment vehicle, please login to see it',
      premiumLabel: 'Premium investment vehicle',
      premiumFund: 'Premium investment vehicle',
      fundSize: 'Total Size of Bonds',
      drawnBond: 'Drawn Bond Contribution',
      undrawnBond: 'Undrawn Bond Contribution',
      interestPaymentDate: 'Fixed Interest Payment Date',
      totalAmountCommitment: 'Total Size of Loans (committed)',
      undrawnAmount: 'Undrawn Portion of Loans',
      numberLoans: 'Number of loans',
      durationLoans: 'Duration loans',
      downloadReports: 'Download reports',
    },
    investments: {
      showPayments: 'Show transactions',
      hidePayments: 'Hide transactions',
      loadAllInvestments: 'Load all investments',
      noPayments: 'There are no investments to show yet',
      requestSupport: 'Request support',
      showFewerPayments: 'Show fewer payments',
      showAllPayments: 'Show all payments',
      active: 'active',
      ended: 'ended',
      started: 'started',
    },
    support: {
      technical: 'Technical',
      technicalQuestion: 'Technical question',
      fundSpecificQuestion: 'Investment vehicle specific question',
      aboutTheFund: 'About the investment vehicle',
      sendMessage: 'Send',
      messageSent: 'Your message has been sent',
      youWillBeAnswered: 'We will answer you by email as soon as possible',
    },
    errors: {
      pdfError: 'There was an error with retrieving the document. Please try again later or contact support',
      noInvestmentsError: 'There are no investments for the selected year',
    },
  },
  settings: {
    details: {
      firstAndLastName: 'First and last name',
      changePassword: 'Change password',
      changeEmail: 'Change e-mail',
      newPasswordOptional: 'New password',
      newPasswordOptionalRepeat: 'Repeat new password',
      confirmWithCurrentPassword: 'Confirm with current password',
      personalDetails: 'Personal details',
      leaveEmpty: 'Leave empty if you do not want to change your password',
      useAtLeastTen: 'Use at least 10 characters to keep your account secure',
      pleaseConfirm: 'In order to change password, please insert the old password first',
      currentPassword: 'Current password',
      passwordToChangeMail: 'Type your current password to change your E-mail address',
      changeHere: 'This is the current IBAN number known to us, change if necessary and click on save',
      mailChanged: 'Your e-mail address has been changed. Please check your e-mail to activate your new e-mail address and login again',
      bankAccountChanged: 'Your bank account was correctly changed',
      passwordChanged: 'Your password has been changed. Please login again',
      nameUpdated: 'Your name has been updated',
      linkSent: 'Please, open the link we just sent to your e-mail in order to confirm the change on your bank account number',
      identity: 'Personal Information',
      credentials: 'Credentials',
    },
    identification: {
      identification: 'Identification',
      complete: 'Verify account',
      support: 'Support',
      title: 'Complete these steps to gain full access',
      userIdentification: 'Create an account',
      registeredToPlatform: 'You have created an account',
      investorIdentification: 'Verify your account',
      completeStep: 'Complete this step and start investing',
      success: 'Your account has been identified',
      rejected: 'Your identification request was rejected',
    },
    tier: {
      currentTier: 'Not applicable',
      cannotRequestTier: 'You cannot request a tier upgrade at the moment',
      workingOnFunctionality: 'We are still working on this functionality',
    },
  },
  help: {
    baseKnowledge: {
      baseKnowledge: 'Base knowledge',
      rics: 'RICS',
      aex: 'AEX',
      afm: 'AFM',
    },
    faq: {
      faq: 'FAQs',
      isIdinSecure: 'Is iDIN secure?',
      howPersonalInformation: 'How is my personal information handled?',
      howInvestDutch: 'How to invest without a Dutch bank account?',
      howInvestCompany: 'How can I invest on behalf of a company?',
      iDINAdheres: 'iDIN adheres to European legislation, i.e.',
      contactOnUpdates: 'We will only contact you for important updates about your account and your investments',
      pleaseSend: 'Please send us a',
      manualRequest: 'manual investment request',
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the investment vehicle, the value of the property is divided pro rata among the shareholders at that time`,
      whenIsItSold: 'When is the real estate project sold / investment vehicle liquidated?',
      theAverage: 'The average duration of a investment vehicle is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the investment vehicles of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my notes?',
      sharesCanBe: 'Notes can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the investment vehicle is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the investment vehicle and real estate management?',
      thatIsBeingCared: 'The investment vehicle managers do this',
    },
  },
  checkout: {
    fund: {
      selectAnAmount: 'select an amount',
      moreInfo: 'more info',
      emissionPricePerShare: 'Price per Note',
      totalFundSize: 'Total investment vehicle size',
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      verifyYourIdentity: 'verify your identity',
      fundDocs: 'Investment vehicle docs',
      noFilesFound: 'no files found',
    },
    investment: {
      selectTheAmount: 'Select the amount in Euro\'s to invest in steps of € {steps}',
      amountIn: 'Amount in',
      investmentCosts: 'Investment',
      emissionCosts: 'Emission costs',
      totalCosts: 'Total cost',
      maximumInvestment: 'Maximum investment with your current account',
      placeOrder: 'place order',
      startThePayment: 'start the payment',
      surpassedMax: `The amount you are trying to buy, added up to what you previously invested in this asset,
        would exceed the limit (€ 100.000).`,
      amountBelowZero: 'Oops. The amount is not a number or smaller than 0.',
      selectAnOption: 'select an option',
      dividends: 'select interest rate yield',
      availableFund: 'The progress bar shows the availability of the investment vehicle',
      legalTerms: 'Legal Terms',
      finalize: 'And finalise the payment',
      questionnaire: 'And move to next step',
    },
    questionnaire: {
      questionnaire: 'Questionnaire',
      mandatory: 'All fields are mandatory',
      errorLoadingQuestions: 'Error loading questions',
    },
    legal: {
      accept: 'Accept Legal Terms',
      continue: 'And pay with Mollie',
      legalAgreement: 'Registration form',
      paymentRecap: 'Payment Recap',
      finalisingPayment: 'You are finalising this payment',
      interestRate: 'Interest rate',
    },
    manualOrder: {
      requestForInvestment: 'Request for investment',
      amountToInvestIn: 'Amount to invest in',
      sendRequest: 'Send request',
      youCanAlsoContact: 'You can also contact us via e-mail or phone',
      weWillGetYouBack: 'We\'ll get back to you within a few working days',
    },
    status: {
      paymentCompleted: 'Payment completed',
      paymentFailed: 'Payment failed',
      paymentNotFound: 'Payment not found',
      paymentOpen: 'Payment is being processed',
      youBought: 'You just bought',
      youTriedToBuy: 'You tried to buy <strong>{shares} notes</strong> for <strong>{euro} Euro</strong>',
      tryAgain: 'try again',
      accountInvestAgain: 'Check other opportunities',
      backToThePortfolio: 'check your investment',
      congratulations: 'Congratulations',
    },
    unlimited: {
      investMoreThan: 'Invest More Than',
      ourProvider: 'Our payment provider (Mollie) currently can not support payments above',
      toInvestMore: 'To invest more than this amount, please send a manual investment request or make multiple payments',
      startRequest: 'Start request for investment',
      youCanEnter: 'You can enter your details and your investment amount here',
      managerContacts: 'Manager contacts',
    },
    errors: {
      notEnoughPermissions: 'Not enough permissions with this credentials',
      somethingWentWrong: 'Whoops, something went wrong during the identification process',
      somethingIsMissing: 'Something is missing',
      somethingIsNotWorking: 'Something is not working',
      problemPersist: 'If the problem persist, please contact us for technical support',
      error404: 'Error 404 - Page not found',
      backToHomepage: 'Back to homepage',
    },
    redirect: {
      text: 'Generating payment via Mollie, our payment service provider...',
    },
  },
  identification: {
    privateInvestor: 'Private investor',
    selectAnOption: 'Select an option',
    dutchBankAccount: 'Invest as private investor with Dutch bank account',
    noDutchBankAccount: 'Invest as private investor with non-Dutch bank account',
    businessTitle: 'Business investor',
    businessInvestment: 'Invest with your business',
    questionnaire: {
      intro: 'Source of wealth',
      submit: 'Submit Answers',
      move: 'And move to identification',
    },
    business: {
      fillFieldsCompany: 'Fill the fields with your company info',
      kvkNumber: 'KVK number',
      uploadYourKvKImage: 'Upload your KvK statement',
      submitYourData: 'Submit your data',
      orContactUs: 'Contact us',
      youCanAlso: 'You can also contact us via e-mail or phone',
      weWillGetBackToYou: 'We\'ll get back to you within a few working days',
    },
    idin: {
      selectAnAmount: 'select an amount',
      identityVerification: 'Identity verification',
      pleaseVerify: 'Please verify your identity using',
      verificationService: 'a verification service provided by Dutch banks',
      completedIdin: 'We\'ve completed your iDIN verification',
      pleaseWait: 'Please wait while we connect to iDIN',
      pleaseSelectAnOption: 'Please, select an option',
      selectBank: 'Select your bank',
      thereWasAnError: 'There was an error with iDIN',
      pleaseContactSupport: 'Please contact support',
      whatIsIdin: 'iDIN explained',
      idinExplanation: `iDIN is a Dutch digital identity provider. Individuals can use iDIN to identify with a company such as {companyName}.
        A person logs in at his or her bank. The bank then passes on the data from the person, who has already been checked by the bank, to {companyName}.
        All customers of the bank that have been fully identified by the bank can use iDIN.`,
      idinError: 'There was an error with iDIN service, please try again or contact support',
    },
    world: {
      fillWithInfo: 'Fill the fields with your info',
      uploadYourPassport: 'Upload your identification document (passport, ID, residence permit, drivers license)',
      submitYourData: 'Submit your data',
    },
    FormStatus: {
      requestSent: 'Request sent!',
      weAreCheckingYourData: 'We are checking your personal information,<br>you will receive an e-mail within a few days with the next steps',
      youAreVerified: 'You are verified and able to invest!',
      exploreProperties: 'Explore properties',
      requestRejected: 'Request rejected!',
      weRejectedYourRequest: 'Your request was rejected, if you think this is an error please contact us',
    },
  },
  properties: {
    exploreProperty: 'More details',
    startDate: 'Started in',
    noResults: 'We could not find any property with this name, please try again',
    emptyPage: 'Login to the Investor Portal of Rax Finance',
  },
  property: {
    propertyInfo: {
      investMore: 'Invest more',
      investOnThisFund: 'Invest in this investment vehicle',
    },
    propertyContent: {
      progress: 'Progress of the investment vehicle',
      investmentCase: 'Investment case',
      propertyDetails: 'About RAX Finance',
      additionalInfo: 'Additional info',
    },
    reservation: {
      title: 'Subscribe for updates',
      confirm: 'Please fill in the mandatory fields to show your interest in <strong>{name}</strong>.',
      error: 'Something went wrong.',
      success: 'Reservation successfully submitted!',
    },
  },
  tooltip: {
    amountOwned: 'This is the total number of the Notes you hold',
    amountGet: 'This is the total amount of Euros you get every month / year',
    fundSize: 'Total value of the investment vehicle',
    availability: 'Available amount of notes',
    netDividendYieldMine: 'Total amount received in interest payments',
    netDividendYield: 'The percentage you will receive per year',
    yourInvestment: 'The total amount you invested in this investment vehicle',
    emissionCosts: 'The fee applied to purchase a note',
    fundProgress: 'Percentage of the investment vehicle that has been sold',
    onlinePayment: 'This payment was made through the platform',
    offlinePayment: 'This payment was made outside of the platform',
    downloadStatement: 'Select the year on the left and click here to download the tax statement you need',
    initialLogin: 'Click here to see your dashboard and investment overview',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Our Data Collection Policy',
      headerText: '{companyName} makes use of \'cookies\' on this page. A cookie is a little piece of information stored on your device. By that we are able to recognise you at your next visit.',
      headerTitle2: 'Why we use cookies',
      /* eslint-disable max-len */
      headerText2: 'We use cookies in order to ensure the functionality and security of our website. Further, this allows us to analyse and improve our site, products, and services. By these cookies we recognise you at the next visit. No phone numbers or e-mail addresses are saved in them. You allow us to use these cookies by saving your preferences by clicking \'save\'. \n\n We use different types of cookies\nIn particular four types of cookies: Functional Cookies, Analytics Cookies, Advertisment, and Personalisation cookies. Find below more details and the selection for the different types of cookies we use.',
      /* eslint-enable max-len */
      bodyButtons: 'Select which information services are activated',
      bodyButtonsDeselect: 'Deselect all',
      bodyButtonsSelect: 'Select all',
      bodySeeMore: 'See more',
      bodySeeLess: 'See less',
    },
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Checkout',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Details',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Glossary',
    },
    identification: {
      title: 'Identification',
    },
    knowledge: {
      title: 'Knowledge base',
    },
    landing: {
      description: 'The description of the Landing Page',
    },
    login: {
      title: 'Login',
      description: 'Login to your account to invest in our properties.',
    },
    properties: {
      title: 'Funds',
      description: 'Browse our properties and find out more about them here.',
    },
    property: {
      description: 'Invest in {name} now! This property is located at {address}. You can start investing from {min} euros.',
    },
    register: {
      title: 'Register',
      description: 'Sign up for {name} and discover our properties. Register with your e-mail to start investing.',
    },
    reset: {
      title: 'Reset password',
    },
    settings: {
      title: 'Settings',
    },
    verification: {
      title: 'Verification',
    },
  },
};

export default {
  en: mergeWithBrandLangFile(original, 'en'),
};
